/* eslint-disable react/jsx-no-target-blank */
import { Img } from '@chakra-ui/react';
import './Footer.scss';
import useTranslations from '../../../hooks/useTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { facebookIcon, instagramIcon } from '../../../assets/icons/icons';
import useIsMobile from '../../../hooks/useIsMobile';
import useIsAdminMode from '../../../hooks/useIsAdminMode';

function Footer() {
  const translator = useTranslations();
  const isMobile = useIsMobile();
  const isAdminMode = useIsAdminMode(window.location.pathname);

  return (
    !isAdminMode && (
      <>
        <div className="footer-container">
          <div className="left-section">
            <Img
              boxSize={isMobile ? '50px' : '50px'}
              minWidth={isMobile ? '50px' : '50px'}
              src={require('../../../assets/images/logo.png')}></Img>
            <Img
              boxSize={isMobile ? '14 0px' : '100px'}
              minWidth={isMobile ? '140px' : '100px'}
              src={require('../../../assets/images/brand.png')}></Img>
          </div>
          <div className="right-section">
            <div className="contact-container">
              <p className="footer-title">{translator('Global.writeUs')}</p>
              <a href="mailto:screalestate.contact@gmail.com">
                screalestate.contact@gmail.com
                <FontAwesomeIcon className="email-arrow" icon={faAnglesRight} />
              </a>
            </div>
            <div className="socials-container">
              <p className="footer-title">{translator('Global.ourSocials')}</p>
              <div className="socials">
                <a
                  className="link-with-icon"
                  href="https://www.instagram.com/sc_realestate_cr/"
                  target="_blank">
                  <div>
                    {instagramIcon()}
                    sc_realestate_cr
                  </div>
                </a>
                <a
                  className="link-with-icon"
                  href="https://www.facebook.com/SyC.Real.Estate/"
                  target="_blank">
                  <div>
                    {facebookIcon()}
                    S&C Real Estate
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          {translator('Global.copyright')}
          <div className="powered">
            {`${translator('Global.poweredBy')}`}{' '}
            <a
              target="_blank"
              className="powered-link"
              href="https://github.com/DevDarkWolf">
              DarkWolf
            </a>
            {` & `}
            <a
              target="_blank"
              className="powered-link"
              href="https://github.com/KuroScar42">
              KuroScar42
            </a>
          </div>
        </div>
      </>
    )
  );
}
export default Footer;
